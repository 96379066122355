import styled from "styled-components";
import Header from "../components/common/HeaderComponent";

const StoryPageContainer = styled.div`
	position: relative;
	display: flex;
	padding-bottom: 48px;
	flex-direction: column;

	p {
		color: var(--color-secondary-text);
		opacity: 0.8;
		text-align: justify;
	}

	h3 {
		font-size: 16px;
		color: var(--color-secondary-text);
		margin: 12px 0 6px;
	}
`;

const StoryTitle = styled.h2`
	font-family: "Lucida Blackletter Regular";
	font-size: 32px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 32px;
	text-align: center;
	margin-bottom: 24px;
`;

const Divider = styled.div`
	width: calc(100% - 48px);
	height: 2px;
	background-color: var(--color-outline);
	margin: 24px 24px 12px;
`;

const SplitContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
`;

const SplitContent = styled.div`
	flex: 1 1;
	min-width: 348px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	h2 {
		font-family: "Lucida Blackletter Regular";
		font-size: 24px;
		font-weight: normal;
		letter-spacing: 0px;
		line-height: 20px;
		text-align: center;
	}

	img {
		width: 350px;
		align-self: center;
	}
`;

const StoryImg = styled.img`
	height: 256px;
	object-fit: contain;
	align-self: center;
	border-radius: 12px;
	margin: 24px 0;

	@media (max-width: 400px) {
		max-width: calc(100% - 24px);
		height: auto;
	}
`;

function StoryPage() {
	return (
		<StoryPageContainer>
			<Header title={"La Storia"} />
			<StoryTitle>
				Quella che narreremo è la vera storia de<br />"La Castello Bene"
			</StoryTitle>
			<p>
				Dal 1975, quarant'anni di divertimento, gioia, serate e notti
				insonni... Bene si parte!!
				<br />
				<br />
				C'erano una volta scalmanati sbarbatelli tipo Fabio Solcà,
				Francesco Gaffuri, Franco Negrini, Fiorenzo Cereghetti, Giorgio
				Regazzoni, Stefano Cantalupi, Paolo Rusca, Marzio Frigerio,
				Angela Bernasconi, Marco Baggi, Edoardo Negri, e Edy Bernasconi,
				che un giorno del lontano 1975 non sapendo cosa fare decisero di
				creare <strong>"IL GRUPPO CARNEVALE CASTELLO"</strong>.<br />
				Ancora in giovane età e di primo pelo (o forse di secondo)
				decisero che a Castel San Pietro cominciava una nuova era.
			</p>
			<StoryImg src="/media/images/Storia2.jpg" alt="storia immagine 1" />
			<h3>E il nome??</h3>
			<p>
				Da dove arriva <strong>"LA CASTELLO BENE"</strong> ?? Quante
				persone se lo sono chiesto... Era la fine degli anni settanta e
				questo giovane gruppo non aveva un nome vero e proprio, la
				difficoltà nasceva ogni qualvolta ci si doveva iscrivere ad una
				sfilata come "Gruppo carnevale castello". In una notte "buia"
				dalle varie difficoltà... vide la luce... La banda si stava
				recando a piedi verso le feste di carnevale al Mercato Coperto
				di Mendrisio e visto il periodo di siccità perenne era dovuta
				una tappa in piazza a Mendrisio. In una distrazione l'amico
				Ragazzoni con pensieri rivolti alla ricerca di dolci donzelle da
				far sue, mise a 90... il povero "Sem" e ad un certo punto si
				udii una voce che diceva: "a ghé scià LA CASTELLO BENE". Tutto
				era compiuto da quel momento la svolta era fatta.
			</p>
			<h3>...costruendo</h3>
			<p>
				Dopo diversi tentativi nel 1979 arriva un grande cambiamento, il
				primo grande carro de "La Castello Bene". Il merito va a un
				personaggio di Castello chiamato dai soci "Pietro Silenzio",
				abile artigiano, le sue mani volavano sulle strutture e
				costruzioni all'impazzata creando e insegnando molto. Via via si
				è sempre migliorato. Nel 1981 per la prima volta si comincia a
				lavorare con il polistirolo. Alla fine degli anni '80
				l'introduzione del secondo rimorchio e qualche anno dopo pure un
				terzo che caratterizza costruzioni mastodontiche. Il lavoro è
				duro ma i risultati sono paganti.
			</p>
			<StoryImg src="/media/images/Storia3.jpg" alt="storia immagine 2" />
			<h3>Che risultati?</h3>
			<p>
				Nel 1988 durante i preparativi per il carnevale '89 Edy
				Bernasconi domanda al Bieto: "ta vöt fa ul segund a Bellinzona
				?" il Bieto raccolse la sfida e mise in opera l'idea dell'amico.
				Il risultato: 1° posto Rabadan, 1° posto Nebiopoli, 1° posto
				Tesserete. La cosa era fatta la società è vincente e da qui via
				nessuno li fermerà più. Svariati podi hanno accompagnato il
				gruppo e nel 2003 un altro grande successo "La cenerentola dei
				Cortei" un premio ambito dai costruttori carri. A seguire negli
				anni i miglioramenti sulle costruzioni, la nuovalinfa arrivata
				dalle nuove leve, hanno portato spirito nuovo ed nel 2017 i
				risultati si sono fatti vedere.......per la seconda volta 1°
				posto Rabadan, 1° posto Nebiopoli, 1° posto
				Tesserete.....strepitoso!
			</p>
			<StoryImg src="/media/images/Storia1.jpg" alt="storia immagine 3" />
			<h3>Condizioni di lavoro:</h3>
			<p>
				Il problema per anni era trovare il posto dove costruire il
				carro. Erano sempre ripari di fortuna,tettoie prestate da gente
				con un grande cuore ma comunque sempre provvisorie per poi
				passare a capannoni affittati per 3-4 mesi dapprima a Capolago
				poi Mendrisio e Castello. Dal 1991 il carro viene costruito a
				Chiasso dove si è trovato una situazione stabile.
			</p>
			<h3>Che musica ragazzi!</h3>
			<p>
				Se non i primi quasi ci siamo... musica fatta in casa! Da molti
				anni il gruppo vanta la produzione di testi musicali adattati al
				tema satirico del carro grazie alla collaborazione del soci
				Alberto e Giorgio con il complesso "Le Nuove Gocce".
				L'originalità innanzitutto! Passano gli anni e l'Alberto è
				sempre presente stavolta aiutato dal Blondy a scrivere i testi e
				il Longhi a registrare.
			</p>
			<h3>Divertirsi fa divertire...</h3>
			<p>
				"La Castello Bene" ora è sinonimo di divertimento, non solo per
				i soci attivi ma per tutta la gente che ama divertirsi, da
				sempre feste animate dal gruppo riscuotono grande successo;
				basta pensare alla corte durante la sagra dell'uva di Mendrisio
				gestita per 15 anni, alla "Troterellata", al corteo in notturna,
				e molte altre manifestazioni in collaborazione con altre
				società. L'allegria è il punto forte.
			</p>
			<h3>I personaggi...</h3>
			<p>
				Cominciamo da capo dove all'inizio erano in dodici e d'acqua
				sotto i ponti ne è passata e di loro ne è rimasto soltanto uno
				attivo o meglio attivissimo è Fiorenzo Cereghetti (Checo) che
				riveste la carica di Re di Castello. Da allora di gente ne è
				passata e chi più chi meno tutti hanno lasciato la loro impronta
				ma sicuramente tutti con nel cuore un gruppo di amici per la
				pelle.
				<br />
				<br />
				Passando alla storia recente dal 1996 la carica di presidente è
				stata assunta da Emanuele Fusi (Lele Füs) succedendo al Bieto e
				difendendo il nome della società alla grande. Nel 2005 Lele
				lascia le redini della società a Enzo Agustoni (Kuki). Un
				comitato misto di esperienza e vivacità porterà avanti la storia
				affrontando nuove sfide.
				<br />
				<br />
				Nel 2007 il Kuki lascia lo scettro al Mirko Negri altra pietra
				miliare della Castello Bene.
				<br />
				<br />
				Siamo arrivati nel 2014 e il Mirko Negri (Fanfulla) lascia il
				ruolo di presidente a Davide Cereghetti (schizzo) un grande mai
				stanco ......e la <strong>STORIA CONTINUA</strong>.
			</p>
			<Divider />
			<SplitContainer>
				<SplitContent>
					<h2>Poesia di El Rupe</h2>
					<img
						src="/media/images/Poesia_ElRupe.jpg"
						alt="poesia di El Rupe"
						loading="lazy"
					/>
				</SplitContent>
				<SplitContent>
					<h2>Il Patto di Castello</h2>
					<img
						src="/media/images/Patto.jpg"
						alt="patto di castello"
						loading="lazy"
					/>
				</SplitContent>
			</SplitContainer>
		</StoryPageContainer>
	);
}

export default StoryPage;
