import { useEffect, useRef } from "react";

/**
 * Custom hook that sets up an Intersection Observer on a DOM element.
 *
 * @param {Function} callback - The function to call when the observed element intersects.
 * @param {Object} [options={ root: null, rootMargin: '0px', threshold: 0.5 }] - Optional configuration options for the Intersection Observer.
 * @param {Element} [options.root=null] - The element that is used as the viewport for checking visibility of the target. Defaults to the browser viewport if null.
 * @param {string} [options.rootMargin='0px'] - Margin around the root. Can have values similar to the CSS margin property.
 * @param {number|number[]} [options.threshold=0.5] - A single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
 * @returns {React.RefObject} - A ref object to be attached to the DOM element to be observed.
 *
 * @example
 * const MyComponent = () => {
 *   const handleIntersection = () => {
 *     console.log('Element is intersecting');
 *   };
 *   const ref = useIntersectionObserver(handleIntersection, { threshold: 0.5 });
 *
 *   return <div ref={ref}>Observe me</div>;
 * };
 */
function useIntersectionObserver(callback, options = { root: null, rootMargin: '0px', threshold: 0.5 }) {
	const ref = useRef(null);

	useEffect(() => {
		const refer = ref.current;

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					callback();
					observer.unobserve(entry.target);
				}
			});
		}, options);

		if (ref.current) observer.observe(ref.current);

		return () => {
			if (refer) observer.unobserve(refer);
		};
	}, [callback, options]);

	return ref;
}

export default useIntersectionObserver;
