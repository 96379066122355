import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ErrorPageContainer = styled.div`
    position: relative;
    margin-bottom: 48px;
`;

function ErrorPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const regex = /(\/\w+(-*\w+)*)+/;

    useEffect(() => {
        // Rederict page
        switch (location.pathname.match(regex)[0]) {
			case "/sponsor/diventare-sponsor":
				navigate("/diventare-sponsor");
				break;
			case "/contattaci/diventare-membro":
				navigate("/diventare-membro");
				break;
			case "/informazioni":
				navigate("/giornalino");
				break;
			case "/category/blog":
				navigate("/archivio-articoli");
				break;

			default:
				break;
		}
    }, [location]);

    return (
        <ErrorPageContainer>
            <Header title={"404 - Pagina non trovata"} />
            <p>OPS... Sembra che la pagina che stai cercando non esiste!</p>
        </ErrorPageContainer>
    );
}

export default ErrorPage;