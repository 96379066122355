import styled from "styled-components";

const NavIconMono = styled.span`
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    font-size: 18px;
    transition: .3s;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&::before {
		content: "${(props) => props.content}";
	}
`;

const CommitteeIconMono = styled.span`
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 18px;
    transition: .3s;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	span {
		font-family: "icomoon" !important;
	}

	span:nth-child(1)::before {
		content: "\\e904";
		opacity: 0.8;
	}
	span:nth-child(2)::before {
		content: "\\e905";
		margin-left: -1em;
	}
	span:nth-child(3)::before {
		content: "\\e906";
		margin-left: -1em;
		opacity: 0.8;
	}
	span:nth-child(4)::before {
		content: "\\e907";
		margin-left: -1em;
	}
`;

const ComicIconMono = styled.span`
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 18px;
    transition: .3s;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	span {
		font-family: "icomoon" !important;
	}

	span:nth-child(1)::before {
		content: "\\e900";
	}
	span:nth-child(2)::before {
		content: "\\e901";
		margin-left: -1em;
		opacity: 0.5;
	}
	span:nth-child(3)::before {
		content: "\\e902";
		margin-left: -1em;
		opacity: 0.5;
	}
	span:nth-child(4)::before {
		content: "\\e903";
		margin-left: -1em;
		opacity: 0.5;
	}
`;

function HomeIcon() {
	return <NavIconMono content="\e909" />;
}

function CommitteeIcon() {
	return (
		<CommitteeIconMono>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</CommitteeIconMono>
	);
}

function StoryIcon() {
	return <NavIconMono content="\e90e" />;
}

function ComicIcon() {
	return (
		<ComicIconMono>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</ComicIconMono>
	);
}

function ProcessionIcon() {
	return <NavIconMono content="\e90b" />;
}

function ContactUsIcon() {
	return <NavIconMono content="\e908" />;
}

function MemberIcon() {
	return <NavIconMono content="\e90a" />;
}

function SponsorIcon() {
	return <NavIconMono content="\e90d" />;
}

function RegulationIcon() {
	return <NavIconMono content="\e90c" />;
}

export {
	HomeIcon,
	CommitteeIcon,
	StoryIcon,
	ComicIcon,
    ProcessionIcon,
    ContactUsIcon,
	MemberIcon,
	SponsorIcon,
	RegulationIcon,
};
