import styled from "styled-components";
import NavigationBar from "../navigation_bar/NavigationComponent";
import { Outlet } from "react-router-dom";
import FooterBar from "../footer_bar/FooterComponent";

const CoordinatorContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;

    @media (max-width: 400px) {
        flex-direction: column;
    }

    main {
        position: relative;
        display: flex; 
        flex-direction: column;
        width: calc(100vw - 360px);
        min-height: 100vh;
        margin-left: 360px;

        @media (max-width: 768px) {
            width: calc(100vw - 80px);
            margin-left: 80px;
        }

        @media (max-width: 400px) {
            width: 100vw;
            min-height: calc(100vh - 80px);
            margin-top: 80px;
            margin-left: 0;
        }
    }
`;

const Resizer = styled.div`
    position: relative;
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin: 48px auto 0;
    padding: 0 12px;
`;

const PageShow = styled.div`
    flex: 1 1 auto;
`;

function CoordinantorLayout() {
    return(
        <CoordinatorContainer>
            <NavigationBar />
            <main>
                <Resizer>
                    <PageShow>
                        <Outlet />
                    </PageShow>
                    <FooterBar />
                </Resizer>
            </main>
        </CoordinatorContainer>
    );
}

export default CoordinantorLayout;