import styled from "styled-components";
import Header from "../../components/common/HeaderComponent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/common/ButtonComponents";

const ProcessionPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;
`;

const ProcessionGrid = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 48px;
`;

const ProcessionPassed = styled.div`
	margin-top: 48px;
	display: flex;
	justify-content: center;

	a {
		text-decoration: none !important;
	}
`;

const ProcessionLink = styled(Link)`
	display: flex;
	width: 275px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 12px;
	color: var(--color-primary) !important;
	border-radius: 6px;
	transition: 0.3s;

	&:hover {
		box-shadow: 0 4px 6px var(--color-shadow);
		text-decoration: none;
	}

	h3 {
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0;
		margin-top: 6px;
	}

	h4 {
		font-size: 12px;
		font-weight: normal;
		line-height: 20px;
		letter-spacing: 0;
	}

	img {
		width: 250px;
		height: 250px;
		object-fit: contain;
	}
`;

function ProcessionPage() {
	const [items, setItems] = useState([]);

	useEffect(() => {
		fetch("/json/cortei.json")
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => setItems(data))
			.catch((error) => console.error("Fetch error:", error));
	}, []);

	return (
		<ProcessionPageContainer>
			<Header title={"I Cortei"} />
			<ProcessionGrid>
				{items.map((item, index) => (
					<ProcessionLink
						to={`/cortei/${item.year}-${item.title
							.toLowerCase()
							.replaceAll(" ", "-")
							.replaceAll("/", "-")
							.replaceAll(",", "")}`}
						key={index}
					>
						<img src={item.image} alt={item.title} loading="lazy" />
						<h3>{item.year}</h3>
						<h4>{item.nikname}</h4>
					</ProcessionLink>
				))}
			</ProcessionGrid>
			<ProcessionPassed>
				<Link to="/cortei-story">
					<Button text="Vedi tutti" />
				</Link>
			</ProcessionPassed>
		</ProcessionPageContainer>
	);
}

export default ProcessionPage;
