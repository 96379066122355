import React, { useState, useEffect } from "react";
import styled from "styled-components";

/**
 * Container for the slideshow.
 */
const SlideShowContainer = styled.div`
	position: relative;
	width: 100%;
	height: 480px;
	transition: 0.3s;
	border-radius: 24px;
	overflow: hidden;

	@media (max-width: 400px) {
		height: 256px;
		border-radius: 12px;
	}
`;

/**
 * Button for navigating the slideshow.
 * @param {boolean} isLeft - Determines if the button is for the left side.
 */
const SlideShowButton = styled.button`
	position: absolute;
	top: calc(50% - 32px);
	${(props) => (props.isLeft ? "left: 12px" : "right: 12px")};
	transform: rotate(${(props) => (props.isLeft ? "180deg" : "0deg")});
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 48px;
	height: 64px;
	color: #ffffff;
	opacity: 0.5;
	background-color: var(--color-primary);
	color: var(--color-background-light);
	font-size: 24px;
	border: none;
	border-radius: 6px;
	transition: 0.3s;
	cursor: pointer;
	z-index: 2;

	i {
		width: 24px;
		height: 24px;
	}

	&:hover {
		opacity: 1;
	}
`;

/**
 * Slide component for displaying an image.
 * @param {boolean} isActive - Determines if the slide is currently active.
 */
const Slide = styled.img`
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	opacity: ${(props) => (props.isActive ? 1 : 0)};
	transition: opacity 1s ease-in-out;
`;

/**
 * SlideShow component to display a series of images with a fade effect.
 * @param {Object} props - Component properties.
 * @param {string[]} props.images - Array of image URLs to display in the slideshow.
 * @param {number} props.interval - Time interval in milliseconds for changing images.
 */
function SlideShow({ images, interval }) {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const slideInterval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, interval); // Cambia immagine ogni intervallo specificato

		return () => clearInterval(slideInterval);
	}, [images.length, interval]);

	return (
		<SlideShowContainer>
			{images.map((image, index) => (
				<Slide
					key={index}
					src={image}
					isActive={index === currentIndex}
				/>
			))}
			<SlideShowButton
				isLeft={true}
				onClick={() =>
					setCurrentIndex(
						(currentIndex - 1 + images.length) % images.length
					)
				}
			>
				<i className="fi fi-sr-play"></i>
			</SlideShowButton>
			<SlideShowButton
				isLeft={false}
				onClick={() =>
					setCurrentIndex((currentIndex + 1) % images.length)
				}
			>
				<i className="fi fi-sr-play"></i>
			</SlideShowButton>
		</SlideShowContainer>
	);
}

export default SlideShow;
