import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import AlertBox from "../components/common/AlertBox";
import LCBLogo from "../images/logo.png";
import CavriLogo from "../images/cavri_logo.jpg";

const RegulationPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;

	h2 {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
		text-align: center;
		margin: 48px 0 24px;
		color: var(--color-title);
	}

	h3 {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
		color: var(--color-primary);
		margin: 24px 0 6px;
	}

	h4 {
		color: var(--color-text);
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 0px;
		line-height: 20px;
		text-decoration: underline;
		margin: 12px 0 6px;
	}
`;

const OrganizerContainer = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 12px;

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 6px;

		img {
			width: 124px;
			height: 124px;
			object-fit: cover;
			border-radius: 6px;
		}
	}
`;

function RegulationPage() {
	return (
		<RegulationPageContainer>
			<Header title={"Regolamento Corteo Castel San Pietro"} />
			<h2>REGOLAMENTO CORTEO NOTTURNA</h2>
			<h3>Partecipazione</h3>
			<ul>
				<li>
					Al corteo mascherato possono partecipare gruppi mascherati,
					2 carri satirici e 6 guggen, per un totale massimo di circa
					600 comparse (per raggiungimento capacità massima del
					corteo).
				</li>
				<li>
					I carri, i gruppi e le guggen dovranno essere pronti per la
					partenza del corteo al più tardi alle 19.45 davanti
					all’Osteria Sulmoni.
				</li>
			</ul>
			<h3>Responsabilità</h3>
			<ul>
				<li>
					I membri del comitato Carnevaa di Cavri e de La Castello
					Bene, declinano ogni responsabilità in caso d’ incidenti,
					infortuni o danni arrecati al pubblico, ai partecipanti, ai
					figuranti, alle comparse, agli addetti ed alle cose prima,
					durante e dopo la manifestazione.
				</li>
			</ul>
			<h3>Rimunerazione</h3>
			<ul>
				<li>
					Nessun gruppo, carro e guggen riceverà alcun premio
					pecuniario, ma soltanto un buono bibita e pasto per ogni
					comparsa.
				</li>
			</ul>
			<h2>REGOLAMENTO SICUREZZA CORTEO NOTTURNA</h2>
			<h3>Circolazione delle persone nel perimetro del carnevale</h3>
			<p>
				La circolazione delle persone presenti è regolata da uno
				specifico regolamento.
				<br />
				Le persone che entrano nel perimetro del carnevale, accettano
				tutti i regolamenti e le disposizioni in vigore.
			</p>
			<h4>Saranno allontanate le persone:</h4>
			<ul>
				<li>
					In evidente stato di ubriachezza o sotto effetto di sostanze
					stupefacenti.
				</li>
				<li>
					Il cui comportamento è di pericolo e/o disturbo o di
					pregiudizio alle persone o allo svolgimento della
					manifestazione.
				</li>
				<li>
					Che nonostante i richiami degli agenti di sicurezza, non si
					attengono ad un comportamento tranquillo ed educato.
				</li>
				<li>
					Che non osservano il presente regolamento e/o le
					disposizioni verbali degli agenti di sicurezza.
				</li>
				<li>Indesiderate, diffidate.</li>
			</ul>
			<h3>
				È vietato portare, introdurre e/o impiegare all’interno dei
				capannoni:
			</h3>
			<ul>
				<li>Armi da fuoco di qualsiasi genere</li>
				<li>
					Spray di qualsiasi genere (anche quelli al pepe destinati
					all’autodifesa)
				</li>
				<li>Coltelli e pugnali di qualsiasi genere</li>
				<li>
					Armi improprie o dispositivi concepiti per ferire le persone
					(es.: tirapugni, manganelli, bastoni, fionde,...)
				</li>
				<li>
					Oggetti contundenti (es.: spranghe e mazze da baseball,...)
				</li>
				<li>
					Dispositivi che producono elettrochoc, puntatori laser, ecc.
				</li>
				<li>
					Bottiglie, lattine e scatole di qualsiasi genere (vetro,
					pet, alluminio,...)
				</li>
				<li>
					Petardi, razzi, bengala, fontanelle, fumogeni, fuochi
					d’artificio di qualsiasi genere
				</li>
				<li>Esplosivi di qualsiasi genere</li>
				<li>Sostanze stupefacenti di qualsiasi genere</li>
			</ul>
			<AlertBox type="warning" title="Attenzione!">
				Questa lista non è esaustiva, gli organizzatori si riservano il
				diritto in ogni momento di emanare altre direttive interne non
				contemplate nel seguente regolamento di sicurezza.
				<br />
				Il materiale ritirato non verrà più ritornato; materiale vietato
				dalla legge verrà consegnato alla polizia.
			</AlertBox>
			<h3>È vietato:</h3>
			<ul>
				<li>
					Fumare nei capannoni e nelle tende (art.57 legge esercizi
					pubblici - art.52 legge sanitaria)
				</li>
				<li>Arrampicarsi sulle installazioni</li>
				<li>Penetrare in zone delimitate e/o non autorizzate</li>
				<li>Impedire e/o ostacolare il passaggio delle persone</li>
				<li>Accendere fuochi</li>
				<li>
					Fare disegni o scritte, commettere danni e/o atti vandalici
				</li>
				<li>Lanciare oggetti o corpi estranei di qualsiasi genere</li>
				<li>Occupare l’area dopo il termine della manifestazione</li>
				<li>
					Importunare, arrecare disturbo, minacciare, aggredire e
					ferire terze persone
				</li>
			</ul>
			<h3>È vietata:</h3>
			<ul>
				<li>
					La vendita ed il consumo di alcolici ai giovani di età
					inferiore di 18 anni
				</li>
				<li>La vendita ed il consumo di stupefacenti</li>
			</ul>
			<h3>Perquisizioni:</h3>
			<p>
				Su semplice richiesta verbale degli agenti di sicurezza, la
				persona deve presentare un documento d’identità. In caso di
				infrazione al presente regolamento i dati possono essere
				registrati.
				<br />
				Si invita lo spettabile pubblico ad attenersi a tutte le
				disposizioni date dagli agenti di sicurezza.
				<br />
				Gli organizzatori si riservano il diritto di pronunciare un
				ammonimento, diffidare, denunciare o chiedere un risarcimento
				per danni a persone o cose come pure di adottare qualsiasi altra
				possibile misura, intesa a far rispettare il presente
				regolamento e le disposizioni in vigore.
				<br />
				Gli organizzatori declinano ogni responsabilità in caso di
				infortuni, incidenti o danni al pubblico.
			</p>
			<h3>Gli organizzatori:</h3>
			<OrganizerContainer>
				<div>
					<img src={CavriLogo} alt="carnevaa di cavri" loading="lazy" />
					<span>CARNEVAA DI CAVRI</span>
				</div>
				<div>
					<img src={LCBLogo} alt="la castello bene" loading="lazy" />
					<span>LA CASTELLO BENE</span>
				</div>
			</OrganizerContainer>
		</RegulationPageContainer>
	);
}

export default RegulationPage;
