import styled from "styled-components";
import Header from "../../components/common/HeaderComponent";
import { useEffect, useState } from "react";
import MusicPlayer from "../../components/music/MusicPlayer";
import useIntersectionObserver from "../../api/LazyObserver";

const ProcessionStoryPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;
`;

const ProcessionStoryPageContent = styled.div`
	position: relative;
	margin-top: 48px;
`;

const TimeLine = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	width: 80px;
	height: 100%;

	div {
		width: 3px;
		height: 100%;
		background: var(--color-outline);
		border-radius: 3px;
	}

	@media (max-width: 400px) {
		display: none;
	}
`;

const StoryList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const StoryItemContent = styled.div`
	position: relative;
	width: 100%;
`;

const StoryYearContent = styled.div`
	position: absolute;
	top: calc(50% - 24px);
	left: 16px;
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: var(--color-primary);
	color: #ffffff;
	border: 3px solid var(--color-background-light);
	border-radius: 50%;

	span {
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 0px;
		line-height: 20px;
	}

	@media (max-width: 400px) {
		position: relative;
		width: 100%;
		left: 0;
		border-radius: 6px;
		margin: 24px 0 12px;
	}
`;

const ContentSplitter = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: 122px;
	gap: 12px;

	@media (max-width: 400px) {
		margin-left: 0px;
	}
`;

const SplitText = styled.div`
	flex: 1 1;
	min-width: 348px;

	p {
		color: var(--color-text-secondary);
		font-size: 18px;
		font-weight: normal;
		letter-spacing: 0px;
		line-height: 20px;
	}
`;

const SplitImage = styled.div`
	flex: 1 1;
	min-width: 250px;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	overflow: hidden;
	justify-content: space-between;
`;

const SingleImg = styled.img`
	max-width: fit-content;
	height: 200px;
	flex: 1 1 auto;
	object-fit: cover;
	border-radius: 12px;
`;

const CoupleImg = styled.img`
	max-width: 100%;
	height: 200px;
	flex: 1 1 auto;
	object-fit: cover;
	border-radius: 12px;
`;

function ProcessionStoryPage() {
	const [items, setItems] = useState([]);

	useEffect(() => {
		fetch("/json/cortei-story.json")
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((data) => setItems(data))
			.catch((error) => console.error("Fetch error:", error));
	}, []);

	return (
		<ProcessionStoryPageContainer>
			<Header title={"La Cortei Story"} />
			<ProcessionStoryPageContent>
				<TimeLine>
					<div></div>
				</TimeLine>
				<StoryList>
					{items.map((item, index) => (
						<StoryItem key={index} item={item} />
					))}
				</StoryList>
			</ProcessionStoryPageContent>
		</ProcessionStoryPageContainer>
	);
}

function StoryItem({ item }) {
	const ref = useIntersectionObserver(() => console.log("Observed element"), {
		root: null,
		rootMargin: "0px",
		threshold: 0.1,
	});

	const useCoupleImg = item.images.length > 1;

	return (
		<StoryItemContent ref={ref}>
			<StoryYearContent>
				<span>{item.year}</span>
			</StoryYearContent>
			<ContentSplitter>
				<SplitText>
					<p dangerouslySetInnerHTML={{ __html: item.title }} />
					{item.music.length > 0 ? (
						item.music.map((src) => <MusicPlayer file={src} autobuffer={false} />)
					) : (
						<></>
					)}
				</SplitText>
				<SplitImage>
					{item.images.map((src, index) =>
						useCoupleImg ? (
							<CoupleImg key={index} src={src} alt={`${item.title} ${index + 1}`} loading="lazy" />
						) : (
							<SingleImg key={index} src={src} alt={item.title} loading="lazy" />
						)
					)}
				</SplitImage>
			</ContentSplitter>
		</StoryItemContent>
	);
}

export default ProcessionStoryPage;
