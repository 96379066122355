import styled from "styled-components";

const ItemContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 56px;
	border-radius: 28px;
	padding: 12px 24px;
	gap: 12px;
	box-shadow: ${(props) =>
		props.isActive ? "0 0 6px var(--color-shadow)" : "none"};
	color: ${(props) =>
		props.isActive
			? "var(--color-on-primary-container)"
			: "var(--color-text)"};
	background-color: ${(props) =>
		props.isActive
			? "var(--color-primary-container)"
			: "var(--color-background-light)"};
	transition: 0.3s;

	&:hover {
		background-color: var(--color-primary-container-hover);
	}

	@media (max-width: 768px) {
		width: ${(props) => (props.isOpen ? "100%" : "56px")};
		padding: 12px ${(props) => (props.isOpen ? "24px" : "16px")};

		
	}
`;

const IconContent = styled.div`
	display: grid;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-items: center;
	padding: 2px;
	color: ${(props) =>
		props.isActive
			? "var(--color-on-primary-container)"
			: "var(--color-text)"};
`;

const TextContent = styled.span`
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 20px;
	font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
	text-decoration: none;

	@media (max-width: 768px) {
		display: ${(props) => (props.isOpen ? "block" : "none")};
	}
`;

function NavigationItem({ title, icon, isActive, isOpen }) {
	return (
		<ItemContainer isActive={isActive} isOpen={isOpen}>
			<IconContent isActive={isActive}>{icon}</IconContent>
			<TextContent isActive={isActive} isOpen={isOpen}>{title}</TextContent>
		</ItemContainer>
	);
}

export default NavigationItem;
