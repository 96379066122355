import styled from "styled-components";

const CardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 300px;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0 0 6px var(--color-shadow);
    gap: 28px;
    -webkit-user-select: none; /* Safari */
    user-select: none;
`;

const ProfileImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 128px;
	height: 128px;
	padding: 4px;
	border: 3px solid var(--color-primary);
	border-radius: 50%;

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
		pointer-events: none;
	}
`;

const ProfileOther = styled.div`
    display: flex;
    flex-direction: column;
    jusify-content: center;
    align-items: center;
    gap: 6px;

    h3 {
        color: var(--color-text);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 20px;
    }

    h4 {
        color: var(--color-primary);
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 20px;
    }

    span.profile-job {
        color: var(--color-text);
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
    }

    a {
        text-decoration: none;
        color: var(--color-text);
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
    }
`;

function ProfileCard({ name, nikname, img, job, children }) {
    return(
        <CardContainer>
            <ProfileImage>
                <img src={img} alt={name + " image"} />
            </ProfileImage>
            <ProfileOther>
                <h3>{ name }</h3>
                <h4>{ nikname }</h4>
            </ProfileOther>
            <ProfileOther>
                <span className="profile-job">{ job }</span>
                { children }
            </ProfileOther>
        </CardContainer>
    );
}

export default ProfileCard;