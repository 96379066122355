import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchFlickrAlbums } from "../../api/FlickrApi";

const AlbumContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 12px;
	margin: 24px 0;
`;

const Album = styled.div`
	position: relative;
	width: 272px;
	height: 150px;
	border-radius: 8px;
	overflow: hidden;
	text-align: center;
	transition: 0.3s;

	&:hover {
		box-shadow: 0 0 6px var(--color-shadow);
	}
`;

const AlbumCover = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 8px;
`;

const AlbumLink = styled.a`
	text-decoration: none;
	color: #ffffff;
	transition: 0.3s;

	h3 {
		position: absolute;
		display: flex;
		align-items: flex-end;
		border-radius: 8px;
		bottom: 0;
		top: 0;
		width: 100%;
		height: 100%;
		text-align: start;
		text-decoration: none;
		white-space: nowrap;
		padding: 6px 12px;
		color: #ffffff;
		background: rgb(0, 0, 0);
		background: linear-gradient(
			45deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 60%
		);
		transition: 0.3s;

		&:hover {
			-webkit-backdrop-filter: brightness(0.8);
			backdrop-filter: brightness(0.8);
		}
	}
`;

function CaroselAlbum({ year }) {
	const apiKey = process.env.REACT_APP_API_FLICKR;
	const userId = "170258017@N06";
	const [albums, setAlbums] = useState([]);

	useEffect(() => {
		const loadAlbums = async () => {
			const fetchedAlbums = await fetchFlickrAlbums(apiKey, userId, year);
			setAlbums(fetchedAlbums);
		};

		loadAlbums();
	}, [apiKey, userId, year]);

	return (
		<AlbumContainer>
			{albums.map((album) => (
				<Album key={album.id}>
					<AlbumLink
						href={`https://www.flickr.com/photos/${userId}/albums/${album.id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<AlbumCover
							src={`https://live.staticflickr.com/${album.server}/${album.primary}_${album.secret}_b.jpg`}
							alt={album.title._content}
						/>
						<h3>
							{
								album.title._content
									.replaceAll(year, "")
									.match(/(\w+(\s*\w*)*)/)[0]
							}
						</h3>
					</AlbumLink>
				</Album>
			))}
		</AlbumContainer>
	);
}

export { CaroselAlbum };
