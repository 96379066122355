import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import { Form, Input, TextArea } from "../components/input/InputComponents";
import { Button } from "../components/common/ButtonComponents";
import {
	MemberIcon,
	SponsorIcon,
} from "../components/navigation_bar/NavigationIcon";
import { Link } from "react-router-dom";
import sendEmailWithTemplate, { infoEmail } from "../api/MailSender";
import { useEffect, useRef, useState } from "react";
import AlertBox from "../components/common/AlertBox";

const ContactUsPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;
`;

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
`;

const FormSplitPart = styled.div`
	flex: 1 1;
	min-width: 250px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	form {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin: 0;
	}
`;

const OtherSplitPart = styled.div`
	flex: 1 1;
	min-width: 250px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
    gap: 12px;
`;

const OtherLink = styled(Link)`
	color: var(--color-primary) !important;
	text-decoration: none !important;
`;

const OtherCard = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 164px;
	height: 164px;
	padding: 12px;
	border-radius: 12px;
	box-shadow: 0 0 6px var(--color-shadow);
	background-color: var(--color-card);
	color: var(--color-primary);
	justify-content: center;
	align-items: center;
	gap: 16px;
	transition: 0.3s;

	&:hover {
		background: var(--color-primary-container-hover);
	}

	span {
		font-size: 72px;
	}

	h3 {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
	}
`;

function ContactUsPage() {
	const [emailState, setEmailState] = useState("");

	const formRef = useRef(null);
	const buttonRef = useRef(null);
	const nameRef = useRef(null);
	const emailRef = useRef(null);
	const subjectRef = useRef(null);
	const messageRef = useRef(null);

	useEffect(() => {
		if (emailState === "loading") {
			buttonRef.current.disable();
		} else { 
			buttonRef.current.enable();
		}
	 }, [emailState]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailState("loading");

		// Get the form data from the input and textarea fields.
		const name = nameRef.current ? nameRef.current.getValue() || "" : "";
		const email = emailRef.current ? emailRef.current.getValue() || "" : "";
		const subject = subjectRef.current
			? subjectRef.current.getValue() || ""
			: "";
		const message = messageRef.current
			? messageRef.current.getValue() || ""
			: "";

		// Check all fields are not empty string.
		if (!name || !email || !subject || !message) {
			console.log("All fields are required.");
			setEmailState("");
			return;
		}

		// Proceed with form submission or other logic
		await sendEmailWithTemplate(
			infoEmail,
			null,
			`La Castello Bene - ${subject}`,
			name,
			message,
			email
		)
			.then((response) => {
				console.log("SUCCESS!", response);
				formRef.current.reset();
				setEmailState("success");
				setTimeout(() => setEmailState(""), 5000);
			})
			.catch((error) => {
				console.log("FAILED...", error);
				setEmailState("error");
			});
	};

	return (
		<ContactUsPageContainer>
			<Header title={"Contattaci"} />
			<Content>
				<FormSplitPart>
					<p>
						Hai idee, domande o curiosità? Compila il modulo e
						faccelo sapere! (* campo obbligatorio)
					</p>
					<Form ref={formRef} onSubmit={handleSubmit}>
						<Input
							ref={nameRef}
							placeholder="Nome"
							autocomplete="name"
							required={true}
						/>
						<Input
							ref={emailRef}
							placeholder="Email"
							autocomplete="email"
							required={true}
							type="email"
						/>
						<Input
							ref={subjectRef}
							placeholder="Oggetto"
							required={true}
						/>
						<TextArea
							ref={messageRef}
							placeholder="Messaggio"
							required={true}
						/>
						<AlertBox
							type={emailState}
							title={getTitle(emailState)}
						>
							{getContent(emailState)}
						</AlertBox>
						<Button
							ref={buttonRef}
							text="Invia"
							alignSelf="flex-end"
						/>
					</Form>
				</FormSplitPart>
				<OtherSplitPart>
					<OtherLink to={"/diventare-membro"}>
						<OtherCard>
							<MemberIcon />
							<h3>Diventa membro</h3>
						</OtherCard>
					</OtherLink>
					<OtherLink to={"/diventare-sponsor"}>
						<OtherCard>
							<SponsorIcon />
							<h3>Diventa sponsor</h3>
						</OtherCard>
					</OtherLink>
				</OtherSplitPart>
			</Content>
		</ContactUsPageContainer>
	);
}

function getTitle(emailState) {
	switch (emailState) {
		case "loading":
			return "Invio in corso...";
		case "success":
			return "Email inviata!";
		case "error":
			return "Email non inviata!";
		default:
			return "";
	}
}

function getContent(emailState) {
	switch (emailState) {
		case "loading":
			return <p>Sto inviando la email, per favore attendi...</p>;
		case "success":
			return (
				<p>
					Email inviata con successo! Ti risponderemo appena possible.
				</p>
			);
		case "error":
			return (
				<p>Errore durante l'invio della email, riprova più tardi.</p>
			);
		default:
			return null;
	}
}

export default ContactUsPage;
