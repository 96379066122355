import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfettiBackground from "../images/confetti.svg";

const Confetti = styled.img`
	position: fixed;
	top: 0;
	left: 360px;
	width: calc(100vw - 360px);
	height: 100vh;
	object-fit: cover;
	z-index: 0;
	pointer-events: none;

	@media (max-width: 768px) {
		width: calc(100vw - 80px);
		left: 80px;
	}

	@media (max-width: 400px) {
		width: 100vw;
		min-height: calc(100vh - 80px);
		top: 80px;
		left: 0;
	}
`;

const ComicPageContainer = styled.div`
	position: relative;
	padding-bottom: 48px;
	z-index: 2px;
	background: var(--color-background-light);

	@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
		background: none;
		-webkit-backdrop-filter: 5px;
		backdrop-filter: blur(5px);
	}

	h2 {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0px;
		line-height: 20px;
		margin: 24px 0 12px;
	}
`;

const ComicGrid = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 48px;
`;

const ComicLink = styled(Link)`
	color: var(--color-primary) !important;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const ComicContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 12px;

	h3 {
		font-size: 16px;
		font-weight: 500;
		line-height: 36px;
		letter-spacing: 0;
	}

	img {
		width: 220px;
		height: 300px;
		object-fit: contain;
		box-shadow: 0 4px 6px var(--color-shadow);
		background: var(--color-background-light);
	}
`;

function ComicPage() {
	const [items, setItems] = useState([]);

	useEffect(() => {
		fetch("/json/giornalino.json")
			.then((response) => response.json())
			.then((data) => setItems(data))
			.catch((error) => console.error(error));
	}, []);

	return (
		<>
			<Confetti src={ConfettiBackground} />
			<ComicPageContainer>
				<Header title="Il Giornalino" />
				<p>
					Da sempre La Castello Bene redige il "giornalino di
					carnevale" ricco di articoli che raccontano vicende e
					aneddoti divertenti dei cittadini legati a Castel San
					Pietro.
					<br />
					<br />
					Una redazione attenta raccoglie le notizie da pubblicare. Se
					desiderate segnalarci qualche fatto o scrivere un articolo
					lo potete fare tramite mail a{" "}
					<a href="mailto:redazione@lacastellobene.ch">
						redazione@lacastellobene.ch
					</a>
					.
				</p>
				<h2>Edizioni passate:</h2>
				<ComicGrid>
					{items.map((item, index) => (
						<ComicLink to={item.pdfUrl} target="_blank">
							<ComicContent key={index}>
								<img src={item.image} alt={item.year} />
								<h3>{"Edizione" + item.year}</h3>
							</ComicContent>
						</ComicLink>
					))}
				</ComicGrid>
			</ComicPageContainer>
		</>
	);
}

export default ComicPage;
