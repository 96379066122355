import styled from "styled-components";
import Header from "../../components/common/HeaderComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Card from "../../components/common/CardComponent";
import MusicPlayer from "../../components/music/MusicPlayer";
import { CaroselAlbum } from "../../components/objects/CaroselComponent";
import YouTube from "react-youtube";

const ProcessionViewPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;

	h2 {
		color: #888888;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0px;
		line-height: 20px;
		margin: 24px 0 12px;
	}

	ul {
		padding-inline-start: 24px;
	}
`;

const ItemHeader = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	div.split-part {
		flex: 1 1;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 230px;

		img {
			width: 230px;
			height: 230px;
			object-fit: contain;
		}
	}
`;

const ProcessionRanking = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	table {
		margin-top: 24px;

		span {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0px;
			line-height: 20px;
		}
	}
`;

const ExtraGrid = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: space-around;

	iframe {
		width: 336px;
		height: 220px;
		border-radius: 12px;
	}
`;

function ProcessionViewPage() {
	const regex = /^(\d+)/;
	const matches = useParams().name.match(regex);
	const [item, setItem] = useState(null);
	const [id, setId] = useState(null);

	useEffect(() => {
		if (matches.length >= 2) {
			setId(matches[1]);
		} else {
			console.error("No match found!");
		}
	}, []);

	console.log(useParams().name, id);

	useEffect(() => {
		fetch(`/json/cortei.json`)
			.then((response) => response.json())
			.then((data) => {
				const selectedItem = data.find((item) => item.year === id);
				console.log(selectedItem);
				setItem(selectedItem);
			})
			.catch((error) => console.error("Fetch error:", error));
	}, [id]); // Dipendenza `id`

	return (
		<ProcessionViewPageContainer>
			{item ? loadItemPage(item) : <Header title={`${id} -`} />}
		</ProcessionViewPageContainer>
	);
}

function loadItemPage(item) {
	console.log(item);

	return (
		<div>
			<Header title={`${item.year} - ${item.title}`} goBack={true} />
			<ItemHeader>
				<div className="split-part">
					<img src={item.image} alt={item.title} loading="lazy" />
				</div>
				<div className="split-part">
					<Card>
						<h3>Classifica</h3>
						<ProcessionRanking>
							<table>
								{item.ranking.map((item, index) => (
									<tr>
										<td key={index}>
											<span
												key={index}
											>{`${item.position}° posto a ${item.where}`}</span>
										</td>
									</tr>
								))}
							</table>
						</ProcessionRanking>
					</Card>
				</div>
			</ItemHeader>
			{item.notopic ? <div></div> : <h2>Tema</h2>}
			<p dangerouslySetInnerHTML={{ __html: item.topic }} />
			<CaroselAlbum year={item.year} />
			<h2>Musica</h2>
			<p>{`Ascolta la Hit del carnevale ${item.year}!`}</p>
			<MusicPlayer file={item.music} />
			{item.extras ? (
				<div>
					<h2>Extra</h2>
					<ExtraGrid>
						{item.extras.map((extra, index) => { 
							if (extra.type === "youtube") { 
								return <YouTube videoId={extra.videoId} />;
							}
						})}
					</ExtraGrid>
				</div>
			) : (<></>)
			}
		</div>
	);
}

export default ProcessionViewPage;
