import axios from "axios";

/**
 * Fetches albums from Flickr.
 * @param {string} apiKey - Flickr API key.
 * @param {string} userId - Flickr user ID.
 * @param {number} year - Year to filter albums by.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of albums.
 */
async function fetchFlickrAlbums(apiKey, userId, year) {
	try {
		const response = await axios.get(
			"https://api.flickr.com/services/rest/",
			{
				params: {
					method: "flickr.photosets.getList",
					api_key: apiKey,
					user_id: userId,
					format: "json",
					nojsoncallback: 1,
				},
			}
		);
		
		const allAlbums = response.data.photosets.photoset;
		const filteredAlbums = allAlbums.filter((album) => {
			const dateCreated = album.title._content.match(/(\d+)/)[0];
			return dateCreated === String(year);
		});

		const sortedAlbums = filteredAlbums.sort(
			(a, b) => a.date_create - b.date_create
		);
		return sortedAlbums;
	} catch (error) {
		console.error("Error fetching albums:", error);
		return [];
	}
}

export { fetchFlickrAlbums };
