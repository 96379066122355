import styled from "styled-components";
import Card from "../components/common/CardComponent";
import { Button } from "../components/common/ButtonComponents";
import SlideShow from "../components/objects/SlideShowComponent";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const HomePageContainer = styled.div`
    position: relative;

    h1 {
        margin: 48px 0 24px;
    }
`;

const CardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 48px 0;
    gap: 24px;
`;

const CardLink = styled(Link)`
	width: fit-content;
	display: flex;
	text-decoration: none !important;
	justify-content: flex-end;
	align-self: flex-end;
`;

function HomePage() {
    const [slideImages, setSlideImages] = useState([]);

	useEffect(() => {
		setSlideImages([
			"/media/slider/Slider2014.jpg",
			"/media/slider/Slider2017.jpg",
			"/media/slider/Slider2018.jpg",
			"/media/slider/Slider2020.jpg",
			"/media/slider/Slider2024.jpg"
		]);
    }, []);

    return (
		<HomePageContainer>
			<SlideShow interval={7500} images={slideImages} />
			<h1>La Castello Bene</h1>
			<p>
				La Castello Bene è una società di carnevale di Castel San Pietro
				fondata nel 1975.
				<br />
				<br />
				Con la costruzione dei nostri carri allegorici animiamo il
				carnevale, partecipando con entusiasmo ai cortei di tutto il
				Cantone e organizzando altri eventi nel corso dell’anno che
				offrono momenti di aggregazione e divertimento.
			</p>
			<CardContainer>
				<Card>
					<h3>Cortei</h3>
					<p>
						<strong>Dai un’occhiata ai nostri cortei!</strong>{" "}
						Rivivi i momenti più divertenti dei nostri carnevali.
					</p>
					<CardLink to="/cortei/">
						<Button alignSelf={"flex-end"} text={"Scopri"} />
					</CardLink>
				</Card>
				<Card>
					<h3>Diventa membro</h3>
					<p>
						<strong>Unisciti a noi!</strong> Vuoi far parte del
						nostro gruppo di carnevale?
					</p>
					<CardLink to="/diventare-membro">
						<Button alignSelf={"flex-end"} text={"Scopri"} />
					</CardLink>
				</Card>
				<Card>
					<h3>Diventa sponsor</h3>
					<p>
						<strong>Sostienici!</strong> Contribuisci a rendere le
						nostre attività ancora più spettacolari.
					</p>
					<CardLink to="/diventare-sponsor">
						<Button alignSelf={"flex-end"} text={"Scopri"} />
					</CardLink>
				</Card>
			</CardContainer>
		</HomePageContainer>
	);
}

export default HomePage;