import styled from "styled-components";

const CardContainer = styled.div`
    position: relative;
    min-width: 200px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0 0 6px var(--color-shadow);
    background-color: var(--color-card);

    h3 {
        color: var(--color-primary);
        font-size: 22px; 
        font-weight: normal;
        letter-spacing: 0px; 
        line-height: 20px;
    }

    p {
        margin: 12px 0 6px;
        flex: 1 1;
    }
`;

function Card({ children }) {
    return(
        <CardContainer>
            { children }
        </CardContainer>
    );
}

export default Card;