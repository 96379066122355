import emailjs from "@emailjs/browser";

export const infoEmail = "info@lacastellobene.ch";

/**
 * Initializes the EmailJS service with specific configuration settings.
 * 
 * @function
 * @returns {void} This function does not return a value.
 */
export function initEmailJS() {
    emailjs.init({
		publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
		// Do not allow headless browsers
		blockHeadless: true,
		blockList: {
			// Block the suspended emails
			list: [],
			// The variable contains the email address
			watchVariable: "userEmail",
		},
		limitRate: {
			// Set the limit rate for the application
			id: "app",
			// Allow 1 request per 10s
			throttle: 10000,
		},
	});
}


/**
 * Sends an email using a predefined template.
 *
 * @param {string} toEmail - The recipient's email address.
 * @param {string} [ccEmail] - The CC email addresses. CC (Carbon Copy) allows all recipients to see each other's email addresses.
 * @param {string} subject - The subject of the email.
 * @param {string} fromName - The sender's name.
 * @param {string} message - The email message.
 * @param {string} replayTo - The reply-to email address.
 * @returns {Promise<Object>} The response data from the email sending service.
 * @throws Will throw an error if the email sending fails or if the required parameters are missing or invalid.
 * 
 * @example
 * sendEmailWithTemplate(
 *     "example@example.com",
 *     "cc@example.com",
 *     "subject",
 *     "from name",
 *     "message",
 *     "replyto@example.com",
 * )
 *		.then((data) => {
 *			console.log("SUCCESS!", response.status, response.text);
 *		})
 *		.catch((error) => {
 *			console.log("FAILED...", error);
 *		});
 */
async function sendEmailWithTemplate(
	toEmail,
	ccEmail = null,
	subject,
	fromName,
	message,
	replyTo
) {
	if (!toEmail || !subject || !fromName || !message || !replyTo) {
		throw new Error("Dati non sufficienti");
	} else if (!isEmail(toEmail)) {
		throw new Error("La email deve essere una email valida");
	}

	const templateParams = {
		to_email: toEmail,
		cc_email: ccEmail,
		subject: subject,
		from_name: fromName,
		message: message,
		reply_to: replyTo,
	};

	emailjs
		.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
			templateParams
		)
        .then((response) => {
            console.log("SUCCESS!", response.status, response.text);
			return response;
		})
		.catch((err) => {
			throw err; // Rilancia l'errore per gestirlo nelle varie pagine
		});
}

const isEmail = (email) => {
	const flags = "gm";
	const pattern = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}";
	const regexPattern = new RegExp(pattern, flags);
	return String(email).match(regexPattern);
};

export default sendEmailWithTemplate;
