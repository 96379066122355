import {
	useEffect,
	useState,
	useRef,
	forwardRef,
	useImperativeHandle,
} from "react";
import styled from "styled-components";

/**
 * Form component styled using styled-components.
 * @component
 */
const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 24px auto 0;
	max-width: 572px;
`;

/**
 * FormRow component styled using styled-components.
 * @component
 */
const FormRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;

	div {
		flex: 1 1;
	}
`;

/**
 * FormColumn component styled using styled-components.
 * @component
 */
const FormColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 12px;
`;

/**
 * InputContainer component styled using styled-components.
 * @component
 * @param {boolean} isFocused - Indicates if the input is focused.
 */
const InputContainer = styled.div`
	position: relative;
	width: 100%;
	height: 48px;
	min-width: 200px !important;
	border: ${(props) => (props.isFocused ? "2px" : "1px")} solid
		var(--color-primary);
	border-radius: 12px;
	transition: 0.3s;
`;

/**
 * Placeholder component styled using styled-components.
 * @component
 * @param {boolean} isFocused - Indicates if the input is focused.
 */
const Placeholder = styled.label`
	position: absolute;
	height: 20px;
	top: ${(props) => (props.isFocused ? "-10px" : "12px")};
	left: ${(props) => (props.isFocused ? "20px" : "8px")};
	padding: 0 4px;
	background: var(--color-background-light);
	color: var(--color-primary);
	font-size: ${(props) => (props.isFocused ? "14px" : "16px")};
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	transition: 0.3s;
`;

/**
 * InputText component styled using styled-components.
 * @component
 */
const InputText = styled.input`
	width: 100%;
	height: 100%;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0;
	line-height: 20px;
	border: none;
	border-radius: 12px;
	background: var(--color-background-light);
	outline: none !important;
	padding: 14px 12px;
`;

/**
 * TextAreaContainer component styled using styled-components.
 * @component
 * @param {boolean} isFocused - Indicates if the textarea is focused.
 */
const TextAreaContainer = styled.div`
	position: relative;
	width: 100%;
	min-width: 200px !important;
	border: ${(props) => (props.isFocused ? "2px" : "1px")} solid
		var(--color-primary);
	border-radius: 12px;
	transition: 0.3s;
`;

/**
 * TextAreaText component styled using styled-components.
 * @component
 */
const TextAreaText = styled.textarea`
	resize: none;
	width: 100%;
	height: 100%;
	min-height: 164px;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0;
	line-height: 20px;
	border: none;
	border-radius: 12px;
	background: var (--color-background-light);
	outline: none !important;
	padding: 14px 12px;
`;

/**
 * Input component for text input.
 * @component
 * @param {("text"|"password"|"email"|"number"|"tel"|"url"|"search"|"date"|"datetime-local"|"month"|"week"|"time"|"color")} type - The type of the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} autocomplete - The autocomplete attribute specifies if browsers should try to predict the value of an input field or not. You can also specify which type of value you expect in the input field.
 * @param {boolean} required - Indicates if the input is required.
 * @param {function} onvaluechange - Callback function to handle value change.
 * @param {string} setvalue - Initial value of the input.
 */
const Input = forwardRef(
	(
		{
			type = "text",
			placeholder = "",
			autocomplete = "on",
			required = false,
			onvaluechange = null,
			setvalue = "",
		},
		ref
	) => {
		const name = placeholder.toLowerCase().replaceAll(" ", "_");

		const [isFocused, setIsFocused] = useState(false);
		const [localValue, setLocalValue] = useState("");
		const inputRef = useRef(null);

		const handleFocus = () => setIsFocused(true);
		const handleBlur = () => setIsFocused(false);

		useEffect(() => {
			setLocalValue(setvalue);
		}, [setvalue]);

		const handleChange = (e) => {
			setLocalValue(e.target.value);
			if (onvaluechange) onvaluechange(e.target, e.target.value);
		};

		useImperativeHandle(ref, () => ({
			getValue: () => {
				return inputRef.current ? inputRef.current.value : "";
			},
		}));

		return (
			<InputContainer isFocused={isFocused}>
				<Placeholder
					id={`label_${name}`}
					isFocused={isFocused || localValue !== ""}
					htmlFor={name}
				>
					{placeholder}
					{required ? "*" : ""}
				</Placeholder>
				<InputText
					ref={inputRef}
					id={name}
					name={name}
					type={type}
					autoComplete={autocomplete}
					value={localValue}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					required={required}
				/>
			</InputContainer>
		);
	}
);

/**
 * TextArea component for multi-line text input.
 * @component
 * @param {string} placeholder - The placeholder text for the textarea.
 * @param {boolean} required - Indicates if the textarea is required.
 * @param {function} onvaluechange - Callback function to handle value change.
 * @param {string} setvalue - Initial value of the textarea.
 */
const TextArea = forwardRef(
	(
		{
			placeholder = "",
			required = false,
			onvaluechange = null,
			setvalue = "",
		},
		ref
	) => {
		const name = placeholder.toLowerCase().replaceAll(" ", "_");

		const [isFocused, setIsFocused] = useState(false);
		const [localValue, setLocalValue] = useState("");
		const textAreaRef = useRef(null);

		const handleFocus = () => setIsFocused(true);
		const handleBlur = () => setIsFocused(false);

		useEffect(() => {
			setLocalValue(setvalue);
		}, [setvalue]);

		const handleChange = (e) => {
			setLocalValue(e.target.value);
			if (onvaluechange) onvaluechange(e.target, e.target.value);
		};

		useImperativeHandle(ref, () => ({
			getValue: () => {
				return textAreaRef.current ? textAreaRef.current.value : "";
			},
		}));

		return (
			<TextAreaContainer isFocused={isFocused}>
				<Placeholder
					id={`label_${name}`}
					isFocused={isFocused || localValue !== ""}
					htmlFor={name}
				>
					{placeholder}
					{required ? "*" : ""}
				</Placeholder>
				<TextAreaText
					ref={textAreaRef}
					id={name}
					name={name}
					value={localValue}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					required={required}
				/>
			</TextAreaContainer>
		);
	}
);

export { Form, FormRow, FormColumn, Input, TextArea };
