import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
	${(props) => !props.isvisible && "overflow: hidden;height: 0px;"}
	position: relative;
	padding: ${(props) => props.isvisible ? "12px 12px 12px 48px" : "0"};
	margin: ${(props) => props.isvisible ? "24px 12px" : "0 12px"};
	color: ${(props) => props.color};
	background-color: ${(props) => props.background};
	border-radius: 12px;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0;
	line-height: 20px;
	-webkit-user-select: none; /* Safari */
	user-select: none;
	transition: 0.3s;

	&::before {
		content: "${(props) => props.icon}";
		position: absolute;
		display: flex;
		top: calc(50% - 12px);
		left: 12px;
		width: 24px;
		height: 24px;
		font-family: uicons-regular-rounded !important;
		font-style: normal;
		font-weight: 400 !important;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 18px;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
`;

const Title = styled.h5`
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	color: ${(props) => props.color};
`;

const spin = keyframes`
	100% {
		transform: rotate(1turn);
	}
`;

const Loading = styled.div`
	position: absolute;
	top: calc(50% - 12px);
	left: 12px;
	display: flex;
	width: 24px;
	height: 24px;
	justify-content: center;
	jusify-content;
	align-items: center;
	padding: 3px;

	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: radial-gradient(farthest-side, #001c3a 94%, #0000) top / 6px 6px no-repeat,
				conic-gradient(#0000 30%, #001c3a);
		-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
		animation: ${spin} 1s infinite linear;
	}
`;

/**
 * A React functional component that renders an alert box with customizable type, title, and children.
 * The alert box changes its appearance based on the provided type.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.type - The type of alert box. Can be one of the following: "error", "success", "info", "warning", or "loading".
 * @param {string} props.title - The title of the alert box.
 * @param {React.ReactNode} props.children - The content to be displayed inside the alert box.
 *
 * @returns {React.ReactElement} - The rendered alert box component.
 */
function AlertBox({ type, title, children }) {
	const [backgroundColor, setBackgroundColor] = useState("");
	const [color, setColor] = useState("");
	const [icon, setIcon] = useState("");
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		switch (type) {
			case "warning":
				setBackgroundColor("#fff2d0");
				setColor("#846a22");
				setIcon("\\fe33");
				setVisible(true);
				break;

			case "error":
				setBackgroundColor("#f7d8db");
				setColor("#732c33");
				setIcon("\\f5eb");
				setVisible(true);
				break;

			case "success":
				setBackgroundColor("#d5eddb");
				setColor("#2e6e3f");
				setIcon("\\f3c4");
				setVisible(true);
				break;

			case "info":
				setBackgroundColor("#cde5fe");
				setColor("#325981");
				setIcon("\\f80b");
				setVisible(true);
				break;

			case "loading":
				setBackgroundColor("#d4e3ff");
				setColor("#001c3a");
				setIcon("");
				setVisible(true);
				break;

			default: // null
				setBackgroundColor("");
				setColor("");
				setIcon("");
				setVisible(false);
				break;
		}
	}, [type]);

	return (
		<Container background={backgroundColor} color={color} icon={icon} isvisible={visible}>
			<Title color={color}>{title}</Title>
			{type === "loading" && (
				<Loading>
					<div></div>
				</Loading>
			)}
			{children}
		</Container>
	);
}

export default AlertBox;
