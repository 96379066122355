import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const HeaderContainer = styled.header`
	position: relative;
	display: flex;
	width: 100%;
	height: 120px;
	padding: 12px;
	margin-bottom: 48px;
	align-items: center;
	background-color: var(--color-primary);
	color: #ffffff;
	border-radius: 6px;
	-webkit-user-select: none; /* Safari */
	user-select: none;
	gap: 12px;

	i {
		display: ${(props) => (props.goBack ? "block" : "none")};
		font-size: 32px;
		width: 32px;
		height: 32px;
		cursor: pointer;
        transition: .3s;

        &:hover {
            opacity: 0.6;
        }

		@media (max-width: 400px) {
			font-size: 24px;
			line-height: 32px;
		}
	}
`;

const HeaderTitle = styled.h1`
	color: #ffffff;

	@media (max-width: 400px) {
		font-size: 24px;
		line-height: 32px;
	}
`;

function Header({ title, goBack = false }) {
    const navigate = useNavigate();

    return (
		<HeaderContainer goBack={goBack}>
			<i class="fi fi-sr-angle-small-left" title="Pagina precedente" onClick={() => navigate(-1)}></i>
			<HeaderTitle>{title}</HeaderTitle>
		</HeaderContainer>
	);
}

export default Header;