import { useImperativeHandle, useState, forwardRef } from "react";
import styled from "styled-components";

const ButtonContainer = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 80px;
	width: fit-content;
	height: 40px;
	padding: 0 12px;
	border-radius: 20px;
	background-color: var(--color-primary);
	color: #ffffff;
	text-decoration: none !important;
	transition: 0.3s;
	font-size: 14px;
	font-weight: normal;
	letter-spacing: 0px;
	line-height: 20px;
	cursor: pointer;
	align-self: ${(props) => props.alignSelf};
	outline: none;
	border: none;

	&:hover {
		background-color: var(--color-primary-container-hover);
		color: var(--color-primary);
		text-decoration: none !important;
	}

	&:disabled {
		background-color: var(--color-disabled);
		cursor: not-allowed;
		pointer-events: none;
	}
`;

/**
 * A styled button component that can be enabled or disabled via imperative methods.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.text - The text to display inside the button.
 * @param {string} [props.alignSelf="flex-start"] - The CSS align-self property value.
 * @param {React.Ref} ref - The ref to access imperative methods.
 * @returns {JSX.Element} The rendered button component.
 *
 * @example
 * const buttonRef = useRef();
 *
 * useEffect(() => {
 *   buttonRef.current.disable();
 *   setTimeout(() => buttonRef.current.enable(), 3000);
 * }, []);
 *
 * return <Button ref={buttonRef} text="Click Me" />;
 */
const Button = forwardRef(({ text, alignSelf = "flex-start" }, ref) => {
	const [isDisabled, setIsDisabled] = useState(false);

	useImperativeHandle(ref, () => ({
		disable: () => {
			setIsDisabled(true);
		},
		enable: () => {
			setIsDisabled(false);
		},
	}));

	return (
		<ButtonContainer alignSelf={alignSelf} disabled={isDisabled}>
			<span>{text}</span>
		</ButtonContainer>
	);
});

export { Button };
