import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/logo.png";
import NavigationItem from "./NavigationItem";
import {
	ComicIcon,
	CommitteeIcon,
	ContactUsIcon,
	HomeIcon,
	MemberIcon,
	ProcessionIcon,
	RegulationIcon,
	SponsorIcon,
	StoryIcon,
} from "./NavigationIcon";

const NavigationContainer = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	width: 360px;
	height: 100vh;
	padding: 24px 12px;
	-webkit-user-select: none; /* Safari */
	user-select: none;
	transition: 0.3s;

	a {
		text-decoration: none;
	}

	@media (max-width: 768px) {
		width: ${(props) => (props.isOpen ? "360px" : "80px")};
		z-index: ${(props) => (props.isOpen ? "12" : "1")};
		background-color: var(--color-background-light);
		box-shadow: ${(props) =>
			props.isOpen ? "0 0 6px var(--color-shadow)" : "none"};
	}

	@media (max-width: 400px) {
		width: 100%;
		height: ${(props) => (props.isOpen ? "100vh" : "80px")};
		overflow: hidden;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 400px) {
		flex-direction: row;
	}
`;

const NavigationHeader = styled(Link)`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	gap: 12px;

	img {
		width: 80px;
		height: 80px;
		object-fit: contain;
	}

	.title-head {
		display: flex;
		flex-direction: column;
		width: 100%;

		h3 {
			color: var(--color-title);
			font-size: 24px;
			letter-spacing: 0px;
			line-height: 32px;
		}

		h4 {
			color: var(--color-title);
			opacity: 0.8;
			font-size: 12px;
			letter-spacing: 0px;
			line-height: 16px;
		}
	}

	@media (max-width: 768px) {
		display: none;
	}

	@media (max-width: 400px) {
		display: flex;

		h3 {
			font-size: 18px;
		}

		img {
			width: 48px;
			height: 48px;
		}
	}
`;

const NavigationHamburger = styled.div`
	position: relative;
	width: 24px;
	height: 24px;
	margin: 0 16px;
	display: none;
	flex-direction: column;
	gap: 5px;
	transition: 0.3s;
	cursor: pointer;

	@media (max-width: 768px) {
		display: flex;
	}

	span {
		position: absolute;
		width: 100%;
		height: 3px;
		background-color: var(--color-primary);
		border-radius: 3px;
		transition: 0.3s;
	}

	span:nth-child(1) {
		top: ${(props) => (props.isOpen ? "10px" : "0")};
		transform: rotate(${(props) => (props.isOpen ? "45deg" : "0deg")});
	}

	span:nth-child(2) {
		top: 10px;
		transform: rotate(${(props) => (props.isOpen ? "-45deg" : "0deg")});
	}

	span:nth-child(3) {
		top: 20px;
		opacity: ${(props) => (props.isOpen ? "0" : "1")};
	}
`;

const NavigationContent = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	gap: 6px;
	margin-top: 64px;

	@media (max-width: 400px) {
		margin-top: 36px;
	}
`;

function NavigationBar() {
	const [activeTab, setActiveTab] = useState("");
	const location = useLocation();
	const [isOpen, setIsOpen] = useState(false);

	React.useEffect(() => {
		const regex = /\/(\w+(-*\w*)*)\/*/;
		const routeMatch = location.pathname.match(regex);
		const newRoute = routeMatch ? routeMatch[1] : "";

		console.log("handle route change here", newRoute);
		setActiveTab(newRoute);
		setIsOpen(false);
	}, [location]);

	return (
		<NavigationContainer isOpen={isOpen}>
			<HeaderContainer>
				<NavigationHeader to="/">
					<img src={logo} alt="lacastellobene" />
					<div className="title-head">
						<h3>La Castello Bene</h3>
						<h4>since 1975</h4>
					</div>
				</NavigationHeader>
				<NavigationHamburger
					isOpen={isOpen}
					onClick={() => setIsOpen(!isOpen)}
				>
					<span></span>
					<span></span>
					<span></span>
				</NavigationHamburger>
			</HeaderContainer>
			<NavigationContent>
				<Link to="/" title="Home">
					<NavigationItem
						title="Home"
						icon={<HomeIcon />}
						isActive={activeTab === ""}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/comitato" title="Comitato">
					<NavigationItem
						title="Comitato"
						icon={<CommitteeIcon />}
						isActive={activeTab === "comitato"}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/storia" title="Storia">
					<NavigationItem
						title="Storia"
						icon={<StoryIcon />}
						isActive={activeTab === "storia"}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/giornalino" title="Gionalino">
					<NavigationItem
						title="Giornalino"
						icon={<ComicIcon />}
						isActive={activeTab === "giornalino"}
						isOpen={isOpen}
					/>
				</Link>
				<div className="divider"></div>
				<Link to="/cortei" title="Cortei">
					<NavigationItem
						title="Cortei"
						icon={<ProcessionIcon />}
						isActive={activeTab === "cortei"}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/cortei-story" title="Cortei story">
					<NavigationItem
						title="Cortei story"
						icon={<ProcessionIcon />}
						isActive={activeTab === "cortei-story"}
						isOpen={isOpen}
					/>
				</Link>
				<div className="divider"></div>
				<Link to="/contattaci" title="Contattaci">
					<NavigationItem
						title="Contattaci"
						icon={<ContactUsIcon />}
						isActive={activeTab === "contattaci"}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/diventare-membro" title="Diventa membro">
					<NavigationItem
						title="Diventa membro"
						icon={<MemberIcon />}
						isActive={activeTab === "diventare-membro"}
						isOpen={isOpen}
					/>
				</Link>
				<Link to="/diventare-sponsor" title="Diventa sponsor">
					<NavigationItem
						title="Diventa sponsor"
						icon={<SponsorIcon />}
						isActive={activeTab === "diventare-sponsor"}
						isOpen={isOpen}
					/>
				</Link>
				<Link
					to="/regolamento-corteo-castel-san-pietro"
					title="Regolamento Corteo CSP"
				>
					<NavigationItem
						title="Reg. Corteo CSP"
						icon={<RegulationIcon />}
						isActive={activeTab === "regolamento-corteo-castel-san-pietro"}
						isOpen={isOpen}
					/>
				</Link>
			</NavigationContent>
		</NavigationContainer>
	);
}

export default NavigationBar;
