import styled from "styled-components";
import Header from "../components/common/HeaderComponent";
import {
	Form,
	FormRow,
	Input,
	TextArea,
} from "../components/input/InputComponents";
import { Button } from "../components/common/ButtonComponents";
import { useEffect, useRef, useState } from "react";
import emptyAmico from "../images/empty_amico.svg";
import AlertBox from "../components/common/AlertBox";
import sendEmailWithTemplate, { infoEmail } from "../api/MailSender";

const BecomeMemberPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;
`;

const EmptyStatePageContainer = styled.div`
	position: relative;

	h2 {
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
		color: var(--color-title);
	}

	img {
		max-height: 450px;
		width: 100%;
		object-fit: contain;
	}

	a {
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 10px;
		opacity: 0.6;
	}
`;

function BecomeMemberPage() {
	const [isEmpty, setIsEmpty] = useState(false);
	const [emailState, setEmailState] = useState("");
	const [form, setForm] = useState("");

	const formRef = useRef(null);
	const buttonRef = useRef(null);
	const nameRef = useRef(null);
	const addressRef = useRef(null);
	const address2Ref = useRef(null);
	const emailRef = useRef(null);
	const telRef = useRef(null);
	const messageRef = useRef(null);

	useEffect(() => {
		if (emailState === "loading") {
			buttonRef.current.disable();
		} else {
			buttonRef.current.enable();
		}
	}, [emailState]);

	useEffect(() => {
		fetch("/json/iscrizione.json")
			.then((response) => response.json())
			.then((data) => {
				if (!data || new Date(String(data.endDate)) < new Date()) {
					setIsEmpty(true); // Data not loaded correctly or date is expired
				} else {
					setForm(data.form);
				}
			})
			.catch((error) => {
				setIsEmpty(true);
				console.error(error);
			});
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailState("loading");

		// Get the form data from the input and textarea fields.
		const name = nameRef.current ? nameRef.current.getValue() : "";
		const address = addressRef.current ? addressRef.current.getValue() : "";
		const address2 = address2Ref.current
			? address2Ref.current.getValue()
			: "";
		const email = emailRef.current ? emailRef.current.getValue() : "";
		const tel = telRef.current ? telRef.current.getValue() : "";
		const message = messageRef.current ? messageRef.current.getValue() : "";

		// Check all required fields are not empty string.
		if (
			name === "" ||
			address === "" ||
			address2 === "" ||
			email === "" ||
			tel === ""
		) {
			console.log("All fields are required.");
			setEmailState("");
			return;
		}

		// Proceed with form submission or other logic
		const planMessage = `${name} ha inoltrato la seguente richiesta di iscrizione:\n\nNome e cognome: ${name}\nIndirizzo: ${address}, ${address2}\nEmail: ${email}\nTelefono: ${tel}\n\nMessaggio: ${message}`;

		sendEmailWithTemplate(
			infoEmail,
			"tiz.solca@hotmail.com",
			"La Castello Bene - Richiesta Iscrizione",
			name,
			planMessage,
			email
		)
			.then((response) => {
				formRef.current.reset();
				console.log("Email sent successfully:", response);
				setEmailState("success");
				setTimeout(() => setEmailState(""), 5000);
			})
			.catch((error) => {
				setEmailState("error");
				console.error("Error while sending email:", error);
			});
	};

	return (
		<BecomeMemberPageContainer>
			<Header title={"Diventa membro"} />
			{isEmpty ? (
				<EmptyState />
			) : (
				<div>
					<p>
						Voui far parte del nostro gruppo di carnevale? Inviaci i
						seguenti dati per completare l'iscrizione! (* campo
						obbligatorio)
					</p>
					<Form ref={formRef} onSubmit={handleSubmit}>
						<FormRow>
							<Input
								ref={nameRef}
								placeholder="Nome e Cognome"
								type="text"
								autocomplete="name"
								required={true}
							/>
						</FormRow>
						<FormRow>
							<Input
								ref={addressRef}
								placeholder="Indirizzo completo"
								type="text"
								autocomplete="street-address"
								required={true}
							/>
							<Input
								ref={address2Ref}
								placeholder="CAP e Città"
								type="text"
								required={true}
							/>
						</FormRow>
						<FormRow>
							<Input
								ref={emailRef}
								placeholder="Email"
								type="email"
								autocomplete="email"
								required={true}
							/>
							<Input
								ref={telRef}
								placeholder="Telefono"
								type="tel"
								autocomplete="tel"
								required={true}
							/>
						</FormRow>
						<TextArea ref={messageRef} placeholder="Messaggio" />
						<AlertBox
							type={emailState}
							title={getTitle(emailState)}
						>
							{getContent(emailState)}
						</AlertBox>
						<Button
							ref={buttonRef}
							text="Invia"
							alignSelf="flex-end"
						/>
					</Form>
				</div>
			)}
		</BecomeMemberPageContainer>
	);
}

function EmptyState() {
	return (
		<EmptyStatePageContainer>
			<h2>Iscrizioni chiuse... Ci vediamo l'anno prossimo!</h2>
			<img src={emptyAmico} alt="Empty freepik" />
			<a href="https://storyset.com/people">
				People illustrations by Storyset
			</a>
		</EmptyStatePageContainer>
	);
}

function getTitle(emailState) {
	switch (emailState) {
		case "loading":
			return "Invio in corso...";
		case "success":
			return "Email inviata!";
		case "error":
			return "Email non inviata!";
		default:
			return "";
	}
}

function getContent(emailState) {
	switch (emailState) {
		case "loading":
			return <p>Sto inviando la email, per favore attendi...</p>;
		case "success":
			return (
				<p>
					Email inviata con successo! Ti risponderemo appena possible.
				</p>
			);
		case "error":
			return (
				<p>Errore durante l'invio della email, riprova più tardi.</p>
			);
		default:
			return null;
	}
}

export default BecomeMemberPage;
