import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CoordinantorLayout from "./components/common/CoordinatorComponent";
import HomePage from "./pages/HomePage";
import CommiteePage from "./pages/CommiteePage";
import StoryPage from "./pages/StoryPage";
import ErrorPage from "./pages/ErrorPage";
import ComicPage from "./pages/ComicPage";
import ProcessionPage from "./pages/procession/ProcessionPage";
import ProcessionViewPage from "./pages/procession/ProcessionViewPage";
import ProcessionStoryPage from "./pages/procession/ProcessionStoryPage";
import ContactUsPage from "./pages/ContactUsPage";
import BecomeMemberPage from "./pages/BecomeMemberPage";
import BecomeSponsorPage from "./pages/sponsor/BecomeSponsorPage";
import RegulationPage from "./pages/RegulationPage";
import { initEmailJS } from "./api/MailSender";

function App() {
	const ruter = createBrowserRouter([
		{
			path: "/",
			element: <CoordinantorLayout />,
			children: [
				{
					path: "/",
					isIndex: true,
					element: <HomePage />,
				},
				{
					path: "/comitato",
					element: <CommiteePage />,
				},
				{
					path: "/storia",
					element: <StoryPage />,
				},
				{
					path: "/giornalino",
					element: <ComicPage />,
				},
				{
					path: "/cortei",
					element: <ProcessionPage />,
				},
				{
					path: "/cortei/:name",
					element: <ProcessionViewPage />,
				},
				{
					path: "/cortei-story",
					element: <ProcessionStoryPage />,
				},
				{
					path: "/contattaci",
					element: <ContactUsPage />,
				},
				{
					path: "/diventare-membro",
					element: <BecomeMemberPage />,
				},
				{
					path: "/diventare-sponsor",
					element: <BecomeSponsorPage />,
				},
				{
					path: "/regolamento-corteo-castel-san-pietro",
					element: <RegulationPage />,
				},
				{
					path: "*",
					element: <ErrorPage />,
				},
			],
		},
	]);

	// Initialize EmailJS
	initEmailJS();

	return (
		<div className="App">
			<RouterProvider router={ruter} />
		</div>
	);
}

export default App;
