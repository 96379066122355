import styled from "styled-components";
import Header from "../../components/common/HeaderComponent";
import {
	Form,
	FormRow,
	Input,
	TextArea,
} from "../../components/input/InputComponents";
import { Button } from "../../components/common/ButtonComponents";
import AlertBox from "../../components/common/AlertBox";
import { useEffect, useRef, useState } from "react";
import sendEmailWithTemplate, { infoEmail } from "../../api/MailSender";

const BecomeSponsorPageContainer = styled.div`
	position: relative;
	margin-bottom: 48px;
`;

function BecomeSponsorPage() {
	const [emailState, setEmailState] = useState("");

	const formRef = useRef(null);
	const buttonRef = useRef(null);
	const compNameRef = useRef(null);
	const refNameRef = useRef(null);
	const emailRef = useRef(null);
	const websiteRef = useRef(null);
	const messageRef = useRef(null);

	useEffect(() => {
		if (emailState === "loading") {
			buttonRef.current.disable();
		} else {
			buttonRef.current.enable();
		}
	}, [emailState]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailState("loading");

		// Get the form data from the input and textarea fields.
		const compName = compNameRef.current
			? compNameRef.current.getValue()
			: "";
		const refName = refNameRef.current ? refNameRef.current.getValue() : "";
		const email = emailRef.current ? emailRef.current.getValue() : "";
		const website = websiteRef.current ? websiteRef.current.getValue() : "";
		const message = messageRef.current ? messageRef.current.getValue() : "";

		// Check all required fields are not empty string.
		if (!compName || !refName || !email) {
			console.log("All fields are required.");
			setEmailState("");
			return;
		}

		// Proceed with form submission or other logic
		const planMessage = `Nome dell'azienda: ${compName}\nPersona di riferimento: ${refName}\nEmail: ${email}\nSito web: ${website}\n\nMessaggio:\n\n${message}`;

		sendEmailWithTemplate(
			infoEmail,
			null,
			"La Castello Bene - Richiesta per diventare Sponsor",
			`${compName} - ${refName}`,
			planMessage,
			email
		)
			.then((response) => {
				formRef.current.reset();
				console.log("Email sent successfully:", response);
				setEmailState("success");
				setTimeout(() => setEmailState(""), 5000);
			})
			.catch((error) => {
				setEmailState("error");
				console.error("Error while sending email:", error);
			});
	};

	return (
		<BecomeSponsorPageContainer>
			<Header title={"Diventa sponsor"} />
			<p>
				Vuoi sostenere la nostra società? Diventa nostro sponsor! (*
				campo obbligatorio)
			</p>
			<Form ref={formRef} onSubmit={handleSubmit}>
				<FormRow>
					<Input
						ref={compNameRef}
						placeholder="Nome dell'azienda"
						autocomplete="organization"
						type="text"
						required={true}
					/>
					<Input
						ref={refNameRef}
						placeholder="Nome di riferimento"
						autocomplete="name"
						type="text"
						required={true}
					/>
				</FormRow>
				<Input
					ref={emailRef}
					placeholder="Email"
					autocomplete="email"
					type="email"
					required={true}
				/>
				<Input
					ref={websiteRef}
					placeholder="Sito web"
					autocomplete="url"
					type="url"
				/>
				<TextArea ref={messageRef} placeholder="Messaggio" />
				<AlertBox type={emailState} title={getTitle(emailState)}>
					{getContent(emailState)}
				</AlertBox>
				<Button ref={buttonRef} text="Invia" alignSelf="flex-end" />
			</Form>
		</BecomeSponsorPageContainer>
	);
}

function getTitle(emailState) {
	switch (emailState) {
		case "loading":
			return "Invio in corso...";
		case "success":
			return "Email inviata!";
		case "error":
			return "Email non inviata!";
		default:
			return "";
	}
}

function getContent(emailState) {
	switch (emailState) {
		case "loading":
			return <p>Sto inviando la email, per favore attendi...</p>;
		case "success":
			return (
				<p>
					Email inviata con successo! Ti risponderemo appena possible.
				</p>
			);
		case "error":
			return (
				<p>Errore durante l'invio della email, riprova più tardi.</p>
			);
		default:
			return null;
	}
}

export default BecomeSponsorPage;
